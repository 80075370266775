exports.components = {
  "component---cache-caches-gatsby-plugin-offline-app-shell-js": () => import("./../../caches/gatsby-plugin-offline/app-shell.js" /* webpackChunkName: "component---cache-caches-gatsby-plugin-offline-app-shell-js" */),
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-about-us-js": () => import("./../../../src/pages/about-us.js" /* webpackChunkName: "component---src-pages-about-us-js" */),
  "component---src-pages-application-development-event-planning-app-in-doha-js": () => import("./../../../src/pages/application-development/event-planning-app-in-doha.js" /* webpackChunkName: "component---src-pages-application-development-event-planning-app-in-doha-js" */),
  "component---src-pages-application-development-services-js": () => import("./../../../src/pages/application-development/services.js" /* webpackChunkName: "component---src-pages-application-development-services-js" */),
  "component---src-pages-application-development-web-js": () => import("./../../../src/pages/application-development/web.js" /* webpackChunkName: "component---src-pages-application-development-web-js" */),
  "component---src-pages-application-development-whatsapp-chatbots-for-patient-bookings-js": () => import("./../../../src/pages/application-development/whatsapp-chatbots-for-patient-bookings.js" /* webpackChunkName: "component---src-pages-application-development-whatsapp-chatbots-for-patient-bookings-js" */),
  "component---src-pages-aviation-industry-js": () => import("./../../../src/pages/aviation-industry.js" /* webpackChunkName: "component---src-pages-aviation-industry-js" */),
  "component---src-pages-blockchain-development-js": () => import("./../../../src/pages/blockchain-development.js" /* webpackChunkName: "component---src-pages-blockchain-development-js" */),
  "component---src-pages-blog-js": () => import("./../../../src/pages/blog.js" /* webpackChunkName: "component---src-pages-blog-js" */),
  "component---src-pages-career-js": () => import("./../../../src/pages/career.js" /* webpackChunkName: "component---src-pages-career-js" */),
  "component---src-pages-case-study-js": () => import("./../../../src/pages/case-study.js" /* webpackChunkName: "component---src-pages-case-study-js" */),
  "component---src-pages-contact-us-js": () => import("./../../../src/pages/contact-us.js" /* webpackChunkName: "component---src-pages-contact-us-js" */),
  "component---src-pages-data-visualization-services-js": () => import("./../../../src/pages/data-visualization-services.js" /* webpackChunkName: "component---src-pages-data-visualization-services-js" */),
  "component---src-pages-devops-services-js": () => import("./../../../src/pages/devops/services.js" /* webpackChunkName: "component---src-pages-devops-services-js" */),
  "component---src-pages-hire-developer-js": () => import("./../../../src/pages/hire-developer.js" /* webpackChunkName: "component---src-pages-hire-developer-js" */),
  "component---src-pages-hire-developers-android-js": () => import("./../../../src/pages/hire-developers/android.js" /* webpackChunkName: "component---src-pages-hire-developers-android-js" */),
  "component---src-pages-hire-developers-angular-js": () => import("./../../../src/pages/hire-developers/angular.js" /* webpackChunkName: "component---src-pages-hire-developers-angular-js" */),
  "component---src-pages-hire-developers-backend-js": () => import("./../../../src/pages/hire-developers/backend.js" /* webpackChunkName: "component---src-pages-hire-developers-backend-js" */),
  "component---src-pages-hire-developers-dot-net-js": () => import("./../../../src/pages/hire-developers/dot-net.js" /* webpackChunkName: "component---src-pages-hire-developers-dot-net-js" */),
  "component---src-pages-hire-developers-flutter-js": () => import("./../../../src/pages/hire-developers/flutter.js" /* webpackChunkName: "component---src-pages-hire-developers-flutter-js" */),
  "component---src-pages-hire-developers-frontend-js": () => import("./../../../src/pages/hire-developers/frontend.js" /* webpackChunkName: "component---src-pages-hire-developers-frontend-js" */),
  "component---src-pages-hire-developers-full-stack-js": () => import("./../../../src/pages/hire-developers/full-stack.js" /* webpackChunkName: "component---src-pages-hire-developers-full-stack-js" */),
  "component---src-pages-hire-developers-ios-js": () => import("./../../../src/pages/hire-developers/ios.js" /* webpackChunkName: "component---src-pages-hire-developers-ios-js" */),
  "component---src-pages-hire-developers-java-js": () => import("./../../../src/pages/hire-developers/java.js" /* webpackChunkName: "component---src-pages-hire-developers-java-js" */),
  "component---src-pages-hire-developers-mobile-app-js": () => import("./../../../src/pages/hire-developers/mobile-app.js" /* webpackChunkName: "component---src-pages-hire-developers-mobile-app-js" */),
  "component---src-pages-hire-developers-node-js-js": () => import("./../../../src/pages/hire-developers/node-js.js" /* webpackChunkName: "component---src-pages-hire-developers-node-js-js" */),
  "component---src-pages-hire-developers-php-js": () => import("./../../../src/pages/hire-developers/php.js" /* webpackChunkName: "component---src-pages-hire-developers-php-js" */),
  "component---src-pages-hire-developers-python-js": () => import("./../../../src/pages/hire-developers/python.js" /* webpackChunkName: "component---src-pages-hire-developers-python-js" */),
  "component---src-pages-hire-developers-react-js-js": () => import("./../../../src/pages/hire-developers/react-js.js" /* webpackChunkName: "component---src-pages-hire-developers-react-js-js" */),
  "component---src-pages-hire-developers-react-native-js": () => import("./../../../src/pages/hire-developers/react-native.js" /* webpackChunkName: "component---src-pages-hire-developers-react-native-js" */),
  "component---src-pages-hire-developers-vue-js-js": () => import("./../../../src/pages/hire-developers/vue-js.js" /* webpackChunkName: "component---src-pages-hire-developers-vue-js-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-mdx-frontmatter-slug-js-content-file-path-content-application-development-agency-banking-solutions-index-mdx": () => import("./../../../src/pages/{Mdx.frontmatter__slug}.js?__contentFilePath=/opt/build/repo/content/application-development/agency-banking-solutions/index.mdx" /* webpackChunkName: "component---src-pages-mdx-frontmatter-slug-js-content-file-path-content-application-development-agency-banking-solutions-index-mdx" */),
  "component---src-pages-mdx-frontmatter-slug-js-content-file-path-content-application-development-digital-payment-trends-index-mdx": () => import("./../../../src/pages/{Mdx.frontmatter__slug}.js?__contentFilePath=/opt/build/repo/content/application-development/digital-payment-trends/index.mdx" /* webpackChunkName: "component---src-pages-mdx-frontmatter-slug-js-content-file-path-content-application-development-digital-payment-trends-index-mdx" */),
  "component---src-pages-mdx-frontmatter-slug-js-content-file-path-content-application-development-digital-wallet-index-mdx": () => import("./../../../src/pages/{Mdx.frontmatter__slug}.js?__contentFilePath=/opt/build/repo/content/application-development/digital-wallet/index.mdx" /* webpackChunkName: "component---src-pages-mdx-frontmatter-slug-js-content-file-path-content-application-development-digital-wallet-index-mdx" */),
  "component---src-pages-mdx-frontmatter-slug-js-content-file-path-content-application-development-doctor-appointment-booking-app-index-mdx": () => import("./../../../src/pages/{Mdx.frontmatter__slug}.js?__contentFilePath=/opt/build/repo/content/application-development/doctor-appointment-booking-app/index.mdx" /* webpackChunkName: "component---src-pages-mdx-frontmatter-slug-js-content-file-path-content-application-development-doctor-appointment-booking-app-index-mdx" */),
  "component---src-pages-mdx-frontmatter-slug-js-content-file-path-content-application-development-event-booking-app-index-mdx": () => import("./../../../src/pages/{Mdx.frontmatter__slug}.js?__contentFilePath=/opt/build/repo/content/application-development/event-booking-app/index.mdx" /* webpackChunkName: "component---src-pages-mdx-frontmatter-slug-js-content-file-path-content-application-development-event-booking-app-index-mdx" */),
  "component---src-pages-mdx-frontmatter-slug-js-content-file-path-content-application-development-implement-an-effective-ekyc-solution-index-mdx": () => import("./../../../src/pages/{Mdx.frontmatter__slug}.js?__contentFilePath=/opt/build/repo/content/application-development/implement-an-effective-ekyc-solution/index.mdx" /* webpackChunkName: "component---src-pages-mdx-frontmatter-slug-js-content-file-path-content-application-development-implement-an-effective-ekyc-solution-index-mdx" */),
  "component---src-pages-mdx-frontmatter-slug-js-content-file-path-content-application-development-international-money-transfer-app-index-mdx": () => import("./../../../src/pages/{Mdx.frontmatter__slug}.js?__contentFilePath=/opt/build/repo/content/application-development/international-money-transfer-app/index.mdx" /* webpackChunkName: "component---src-pages-mdx-frontmatter-slug-js-content-file-path-content-application-development-international-money-transfer-app-index-mdx" */),
  "component---src-pages-mdx-frontmatter-slug-js-content-file-path-content-application-development-mental-health-index-mdx": () => import("./../../../src/pages/{Mdx.frontmatter__slug}.js?__contentFilePath=/opt/build/repo/content/application-development/mental-health/index.mdx" /* webpackChunkName: "component---src-pages-mdx-frontmatter-slug-js-content-file-path-content-application-development-mental-health-index-mdx" */),
  "component---src-pages-mdx-frontmatter-slug-js-content-file-path-content-application-development-qr-code-for-payments-index-mdx": () => import("./../../../src/pages/{Mdx.frontmatter__slug}.js?__contentFilePath=/opt/build/repo/content/application-development/qr-code-for-payments/index.mdx" /* webpackChunkName: "component---src-pages-mdx-frontmatter-slug-js-content-file-path-content-application-development-qr-code-for-payments-index-mdx" */),
  "component---src-pages-mdx-frontmatter-slug-js-content-file-path-content-software-development-ai-in-fuel-station-operations-index-mdx": () => import("./../../../src/pages/{Mdx.frontmatter__slug}.js?__contentFilePath=/opt/build/repo/content/software-development/ai-in-fuel-station-operations/index.mdx" /* webpackChunkName: "component---src-pages-mdx-frontmatter-slug-js-content-file-path-content-software-development-ai-in-fuel-station-operations-index-mdx" */),
  "component---src-pages-mdx-frontmatter-slug-js-content-file-path-content-software-development-best-practices-index-mdx": () => import("./../../../src/pages/{Mdx.frontmatter__slug}.js?__contentFilePath=/opt/build/repo/content/software-development/best-practices/index.mdx" /* webpackChunkName: "component---src-pages-mdx-frontmatter-slug-js-content-file-path-content-software-development-best-practices-index-mdx" */),
  "component---src-pages-mdx-frontmatter-slug-js-content-file-path-content-software-development-cost-and-pricing-models-index-mdx": () => import("./../../../src/pages/{Mdx.frontmatter__slug}.js?__contentFilePath=/opt/build/repo/content/software-development/cost-and-pricing-models/index.mdx" /* webpackChunkName: "component---src-pages-mdx-frontmatter-slug-js-content-file-path-content-software-development-cost-and-pricing-models-index-mdx" */),
  "component---src-pages-mdx-frontmatter-slug-js-content-file-path-content-software-development-cost-index-mdx": () => import("./../../../src/pages/{Mdx.frontmatter__slug}.js?__contentFilePath=/opt/build/repo/content/software-development/cost/index.mdx" /* webpackChunkName: "component---src-pages-mdx-frontmatter-slug-js-content-file-path-content-software-development-cost-index-mdx" */),
  "component---src-pages-mdx-frontmatter-slug-js-content-file-path-content-software-development-healthcare-information-systems-index-mdx": () => import("./../../../src/pages/{Mdx.frontmatter__slug}.js?__contentFilePath=/opt/build/repo/content/software-development/healthcare-information-systems/index.mdx" /* webpackChunkName: "component---src-pages-mdx-frontmatter-slug-js-content-file-path-content-software-development-healthcare-information-systems-index-mdx" */),
  "component---src-pages-mdx-frontmatter-slug-js-content-file-path-content-software-development-last-mile-delivery-index-mdx": () => import("./../../../src/pages/{Mdx.frontmatter__slug}.js?__contentFilePath=/opt/build/repo/content/software-development/last-mile-delivery/index.mdx" /* webpackChunkName: "component---src-pages-mdx-frontmatter-slug-js-content-file-path-content-software-development-last-mile-delivery-index-mdx" */),
  "component---src-pages-mdx-frontmatter-slug-js-content-file-path-content-software-development-latest-technologies-index-mdx": () => import("./../../../src/pages/{Mdx.frontmatter__slug}.js?__contentFilePath=/opt/build/repo/content/software-development/latest-technologies/index.mdx" /* webpackChunkName: "component---src-pages-mdx-frontmatter-slug-js-content-file-path-content-software-development-latest-technologies-index-mdx" */),
  "component---src-pages-mdx-frontmatter-slug-js-content-file-path-content-software-development-life-cycle-index-mdx": () => import("./../../../src/pages/{Mdx.frontmatter__slug}.js?__contentFilePath=/opt/build/repo/content/software-development/life-cycle/index.mdx" /* webpackChunkName: "component---src-pages-mdx-frontmatter-slug-js-content-file-path-content-software-development-life-cycle-index-mdx" */),
  "component---src-pages-mdx-frontmatter-slug-js-content-file-path-content-software-development-partner-index-mdx": () => import("./../../../src/pages/{Mdx.frontmatter__slug}.js?__contentFilePath=/opt/build/repo/content/software-development/partner/index.mdx" /* webpackChunkName: "component---src-pages-mdx-frontmatter-slug-js-content-file-path-content-software-development-partner-index-mdx" */),
  "component---src-pages-mdx-frontmatter-slug-js-content-file-path-content-software-development-remote-patient-monitoring-index-mdx": () => import("./../../../src/pages/{Mdx.frontmatter__slug}.js?__contentFilePath=/opt/build/repo/content/software-development/remote-patient-monitoring/index.mdx" /* webpackChunkName: "component---src-pages-mdx-frontmatter-slug-js-content-file-path-content-software-development-remote-patient-monitoring-index-mdx" */),
  "component---src-pages-mdx-frontmatter-slug-js-content-file-path-content-software-development-trends-index-mdx": () => import("./../../../src/pages/{Mdx.frontmatter__slug}.js?__contentFilePath=/opt/build/repo/content/software-development/trends/index.mdx" /* webpackChunkName: "component---src-pages-mdx-frontmatter-slug-js-content-file-path-content-software-development-trends-index-mdx" */),
  "component---src-pages-mdx-frontmatter-slug-js-content-file-path-content-software-development-warehouse-management-systems-wms-index-mdx": () => import("./../../../src/pages/{Mdx.frontmatter__slug}.js?__contentFilePath=/opt/build/repo/content/software-development/warehouse-management-systems-wms/index.mdx" /* webpackChunkName: "component---src-pages-mdx-frontmatter-slug-js-content-file-path-content-software-development-warehouse-management-systems-wms-index-mdx" */),
  "component---src-pages-official-channel-partner-js": () => import("./../../../src/pages/official-channel-partner.js" /* webpackChunkName: "component---src-pages-official-channel-partner-js" */),
  "component---src-pages-ripple-button-js": () => import("./../../../src/pages/RippleButton.js" /* webpackChunkName: "component---src-pages-ripple-button-js" */),
  "component---src-pages-services-product-engineering-js": () => import("./../../../src/pages/services/product-engineering.js" /* webpackChunkName: "component---src-pages-services-product-engineering-js" */),
  "component---src-pages-software-development-ai-js": () => import("./../../../src/pages/software-development/ai.js" /* webpackChunkName: "component---src-pages-software-development-ai-js" */),
  "component---src-pages-software-development-fintech-js": () => import("./../../../src/pages/software-development/fintech.js" /* webpackChunkName: "component---src-pages-software-development-fintech-js" */),
  "component---src-pages-software-development-healthcare-js": () => import("./../../../src/pages/software-development/healthcare.js" /* webpackChunkName: "component---src-pages-software-development-healthcare-js" */),
  "component---src-pages-software-development-logistics-js": () => import("./../../../src/pages/software-development/logistics.js" /* webpackChunkName: "component---src-pages-software-development-logistics-js" */),
  "component---src-pages-software-development-machine-learning-js": () => import("./../../../src/pages/software-development/machine-learning.js" /* webpackChunkName: "component---src-pages-software-development-machine-learning-js" */),
  "component---src-pages-software-development-manufacturing-js": () => import("./../../../src/pages/software-development/manufacturing.js" /* webpackChunkName: "component---src-pages-software-development-manufacturing-js" */),
  "component---src-pages-software-development-oil-and-gas-js": () => import("./../../../src/pages/software-development/oil-and-gas.js" /* webpackChunkName: "component---src-pages-software-development-oil-and-gas-js" */),
  "component---src-pages-software-development-retail-js": () => import("./../../../src/pages/software-development/retail.js" /* webpackChunkName: "component---src-pages-software-development-retail-js" */),
  "component---src-pages-software-development-services-js": () => import("./../../../src/pages/software-development/services.js" /* webpackChunkName: "component---src-pages-software-development-services-js" */),
  "component---src-pages-thankyou-js": () => import("./../../../src/pages/thankyou.js" /* webpackChunkName: "component---src-pages-thankyou-js" */),
  "component---src-templates-author-js": () => import("./../../../src/templates/author.js" /* webpackChunkName: "component---src-templates-author-js" */),
  "component---src-templates-categories-js": () => import("./../../../src/templates/categories.js" /* webpackChunkName: "component---src-templates-categories-js" */)
}

